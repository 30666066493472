const PARK_TYPES = {
  ROADSIDE: 'Придорожная',
  MULTILEVEL: 'Многоуровневая',
  PLANAR: 'Плоскостная',
  COMMERCE: 'Коммерческая',
  FREE: 'Бесплатная',
  FORDISABLED: 'Парковка для инвалидов',
  POCKET: 'Карман',
  PARKOMAT: 'Паркомат',
  PAYMENTTERMINAL: 'Пункт приема оплаты',
  INFORMATIONTABLE: 'Информационное табло',
  MUSEUM: 'Музей',
  SIGHT: 'Достопримечательность',
  CHURCH: 'Церковь',
  RESIDENT: 'Парковка для резидентов',
  TAXI: 'Парковка для такси',
  BUS: 'Парковка для автобусов',
  DEFAULT: 'default'
};

export default PARK_TYPES;
